import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Col } from 'antd';

const BusinessAddressPreview = ({ business }) => {
  const { getText } = useTranslations();

  return (
    <>
      <Col lg={4} span={24}>
        <label>{getText('country')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.country}</p>
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('area')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.area ?? '-'}</p>
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('stateProvince')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.state ?? '-'}</p>
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('city')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.city ?? '-'}</p>
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('postcode')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.postcode ?? '-'}</p>
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('addressLine')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.street ?? '-'}</p>
      </Col>
    </>
  );
};

BusinessAddressPreview.propTypes = {
  deal: PropTypes.object,
  business: PropTypes.object,
};

export default BusinessAddressPreview;
