import { getText } from '@veraio/strank';
import { invert } from 'lodash-es';
import { TAG_TYPE } from 'components/UIExternal/Tag';

export const businessStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
  edited: 4,
};

export const businessStatusInverse = invert(businessStatus);

export const businessStatusColor = {
  [businessStatus.pending]: TAG_TYPE.YELLOW,
  [businessStatus.approved]: TAG_TYPE.GREEN,
  [businessStatus.declined]: TAG_TYPE.RED,
  [businessStatus.edited]: TAG_TYPE.GRAY_LIGHT,
};

export const businessStatusSelect = () =>
  Object.entries(businessStatus).map(([label, value]) => ({ label: getText(label), value }));

export const businessType = {
  individual: 1,
  business: 2,
};

export const businessTypeInverse = invert(businessType);

export const businessTypeSelect = () =>
  Object.entries(businessType).map(([label, value]) => ({ label: getText(label), value }));

export const businessAddressStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
  edited: 4,
};

export const businessAddressStatusInverse = invert(businessAddressStatus);

export const businessAddressStatusColor = {
  [businessAddressStatus.pending]: TAG_TYPE.YELLOW,
  [businessAddressStatus.approved]: TAG_TYPE.GREEN,
  [businessAddressStatus.declined]: TAG_TYPE.RED,
  [businessAddressStatus.edited]: TAG_TYPE.GRAY_LIGHT,
};

export const businessAddressStatusSelect = () =>
  Object.entries(businessAddressStatus).map(([label, value]) => ({ label: getText(label), value }));
