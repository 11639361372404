import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { isEmpty } from 'lodash-es';
import { AutoComplete, Avatar, Col, Input, Modal, Popover, Row } from 'antd';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { BorderlessShadowedBox, TextInput, Icon } from 'components/ui';
import ListingDeal from 'components/deal/ListingDeal';
import { BaseCheckbox } from 'components/ui/Checkboxes';
import { useUser } from 'stores/contexts/User';
import { delay } from 'utils/queryUtils';
import {
  getChatMetadata,
  getChatModerator,
  getMetaDataForUserAndDeal,
  leaveChat,
  markChatAsArchived,
  renameChat,
  searchUsers,
  startChat,
} from 'services/api/chatService';
import useError from 'services/errorHandling/useError';
import Messages from './Messages';
import { participantSelector, search, searchResultWrapper, linkBtnStyle, bigLinkBtnStyle } from './styles';

export const OngoingChat = () => {
  const history = useHistory();
  const { getText } = useTranslations();
  const { setError } = useError();
  const { dealId, chatId, userId } = queryString.parse(history.location.search);
  const isUserOrDealPreloaded = dealId || userId;
  const currentUser = useUser()?.userInfo;
  const [metaData, setMetadata] = useState({});
  const isGroupChat = Object.keys(metaData?.participants || {})?.length > 1;
  const [searchResult, setSearchResult] = useState([]);
  const [deal, setDeal] = useState({});
  const [participants, setParticipants] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [isModeratorAdded, setIsModeratorAdded] = useState(false);
  const [isParticipantsModalOpen, setIsParticipantsModalOpen] = useState(false);
  const [isRenameGroupModalOpen, setIsRenameGroupModalOpen] = useState(false);
  const [modalConfirmLoading, setModalConfirmLoading] = useState(false);

  useEffect(() => {
    if (chatId?.length && currentUser) {
      getChatMetadata(chatId, setError).then((metaDataRes) => {
        metaDataRes && setMetadata(metaDataRes);
        metaDataRes?.participants && setNewParticipants(Object.values(metaDataRes.participants));
      });
    }
  }, [chatId, currentUser]);

  useEffect(() => {
    participants.length &&
      !chatId &&
      getMetaDataForUserAndDeal(
        participants.map((participant) => participant.id),
        dealId || null,
        setError,
      ).then((r) => {
        if (r) {
          setMetadata(r);
          setDeal(r?.dealDetail);
        }
      });
  }, [participants, dealId, userId]);

  useEffect(() => {
    userId &&
      getMetaDataForUserAndDeal([userId], dealId || null, setError).then((r) => {
        if (r) {
          setParticipants(Object.values(r.participants));
          setDeal(r?.dealDetail);
        }
      });
  }, [dealId, userId]);

  const onSearch = (e) => {
    e.length > 2 &&
      delay(() =>
        searchUsers(e, setError).then(
          (res) => res && setSearchResult(res.map((user) => ({ label: user.username, value: user.id, ...user }))),
        ),
      );
  };

  const onNewChatUserSelect = (participant) => {
    if (!participants.find((part) => part.id === participant.id)) {
      const temp = [...participants];
      temp.push(participant);
      setParticipants(temp);
    }
  };

  const onSelect = (participant) => {
    if (!newParticipants.find((part) => part.id === participant.id)) {
      const temp = [...newParticipants];
      temp.push(participant);
      setNewParticipants(temp);
    }
  };

  const toggleParticipantsModal = () => {
    setModalConfirmLoading(false);
    setIsParticipantsModalOpen(!isParticipantsModalOpen);
  };

  const toggleRenameModal = () => {
    setModalConfirmLoading(false);
    setIsRenameGroupModalOpen(!isRenameGroupModalOpen);
  };

  const onEditParticipants = () => {
    setModalConfirmLoading(true);
    startChat(
      newParticipants.map((participant) => participant.id),
      setError,
    ).then((r) => r && history.push(`/messages/chat?chatId=${r}`));
  };

  const onEditGroupName = () => {
    setModalConfirmLoading(true);
    renameChat(newGroupName, chatId, setError).then(() =>
      getChatMetadata(chatId, setError).then((metaDataRes) => {
        toggleRenameModal();
        metaDataRes && setMetadata(metaDataRes);
      }),
    );
  };

  const mapParticipantsForExistingChat = () => (
    <BorderlessShadowedBox css={participantSelector}>
      <div className="flex popover">
        <Popover
          trigger="click"
          placement="bottom"
          content={
            <>
              {isGroupChat && (
                <Button type="link" small onClick={toggleRenameModal} css={linkBtnStyle}>
                  {getText('renameGroup')}
                </Button>
              )}
              <Button type="link" small onClick={toggleParticipantsModal} css={linkBtnStyle}>
                {getText('editParticipantList')}
              </Button>
              <Button
                type="link"
                small
                onClick={() => {
                  isGroupChat
                    ? leaveChat(chatId, setError).then(() => history.push('/messages'))
                    : markChatAsArchived(chatId, setError).then(() => history.push('/messages'));
                }}
                css={linkBtnStyle}>
                {isGroupChat ? getText('leaveGroup') : getText('markAsArchived')}
              </Button>
            </>
          }>
          {/* We need this div because Popover puts ref to its only child to append onClick method which opens the popup
        But our icon do not accept ref and it is not acceptable to have ref on icon, so we just add parent span */}
          <span>
            <Icon iconName="las la-ellipsis-v" />
          </span>
        </Popover>
      </div>
      <Avatar.Group maxCount={2} className="flex flex-center">
        {metaData?.participants &&
          Object.values(metaData.participants)?.map((participant, i) => (
            <Avatar key={i} src={participant.avatarThumbnailUrl}>
              {participant.firstName?.charAt(0)}
            </Avatar>
          ))}
      </Avatar.Group>
      <div className="flex flex-column flex-center">
        <p>{metaData?.name}</p>
        <label>{getText('started')}</label>
        <label>{moment(metaData?.createDate).format('DD/MM/YYYY · hh:mm')}</label>
      </div>
    </BorderlessShadowedBox>
  );

  const mapParticipantsForNewChat = () =>
    participants.map((participant, i) => (
      <div key={i} className="flex space-between wrapper">
        <div className="flex flex-center">
          <Avatar src={participant?.avatarThumbnailUrl}>{participant?.firstName?.charAt(0)}</Avatar>
          <p>{`${participant?.firstName} ${participant?.lastName}`}</p>
        </div>
        {!isUserOrDealPreloaded && (
          <BaseCheckbox
            onChange={() => {
              setParticipants(participants.filter((part) => part?.id !== participant?.id));
              isModeratorAdded && setIsModeratorAdded(false);
            }}
            checked
          />
        )}
      </div>
    ));

  const newChatParticipantsSection = (
    <>
      <Button type="link" small linkTo="/messages" css={linkBtnStyle}>
        <Icon iconName="las la-arrow-left" />
        <label>{getText('allMessages')}</label>
      </Button>
      {!isUserOrDealPreloaded && !isModeratorAdded && (
        <AutoComplete
          style={{ width: '100%' }}
          css={search}
          dropdownClassName={searchResultWrapper}
          notFoundContent={getText('noResultsFound')}
          options={searchResult}
          onSelect={(val, option) => onNewChatUserSelect(option)}>
          <TextInput showSearch onSearch={onSearch} noValue placeholder={getText('searchByUsername')} />
        </AutoComplete>
      )}
      {!isEmpty(deal) && <ListingDeal mainDealName={deal.name} deal={deal} isVertical />}
      {participants?.length ? (
        <BorderlessShadowedBox css={participantSelector}>{mapParticipantsForNewChat()}</BorderlessShadowedBox>
      ) : (
        <></>
      )}
      {!isModeratorAdded && (
        <Button
          type="link"
          small
          onClick={() =>
            getChatModerator(dealId || null, setError).then((r) => {
              setParticipants(r ? [r] : [{ firstName: getText('noModeratorsFound'), lastName: '', id: 0 }]);
              setIsModeratorAdded(true);
            })
          }
          css={bigLinkBtnStyle}>
          {getText('orMessageModerator')}
        </Button>
      )}
    </>
  );

  const existingChatParticipantsSection = (
    <>
      <Button type="link" small linkTo="/messages" css={bigLinkBtnStyle}>
        <Icon iconName="las la-arrow-left" />
        <label>{getText('allMessages')}</label>
      </Button>

      {metaData.dealDetail ? (
        <ListingDeal mainDealName={metaData.dealDetail.name} deal={metaData.dealDetail} isVertical />
      ) : (
        mapParticipantsForExistingChat()
      )}
      <Button
        type="link"
        small
        onClick={() =>
          delay(() => {
            getChatModerator(metaData?.dealDetail?.id || null, setError).then(
              (r) =>
                r &&
                history.push(
                  `/messages/chat?userId=${r?.id}${metaData?.dealDetail ? `&dealId=${metaData.dealDetail.id}` : ''}`,
                ),
            );
          })
        }
        css={linkBtnStyle}>
        {getText('orMessageModerator')}
      </Button>
    </>
  );

  const editParticipantsModalContent = (
    <>
      <AutoComplete
        style={{ width: '100%', marginBottom: 30, marginTop: 30 }}
        css={search}
        dropdownClassName={searchResultWrapper}
        notFoundContent={getText('noResultsFound')}
        options={searchResult}
        onSelect={(val, option) => onSelect(option)}>
        <TextInput showSearch noValue onSearch={onSearch} placeholder={getText('searchForUsers')} />
      </AutoComplete>
      {newParticipants.map((participant, i) => (
        <div key={i} className="flex space-between" style={{ marginBottom: 15 }}>
          <div className="flex flex-center">
            <Avatar src={participant?.avatarThumbnailUrl}>{participant?.firstName?.charAt(0)}</Avatar>
            <label style={{ marginLeft: 10 }}>{`${participant?.firstName} ${participant?.lastName}`}</label>
          </div>
          <Button
            type="link"
            small
            onClick={() => setNewParticipants(newParticipants.filter((part) => part?.id !== participant?.id))}
            css={linkBtnStyle}>
            <Icon iconName="las la-times" />
          </Button>
        </div>
      ))}
    </>
  );

  const renameGroupModalContent = (
    <Input
      style={{ marginTop: 30 }}
      onChange={(e) => setNewGroupName(e.target.value)}
      placeholder={getText('pleaseEnterGroupChatName')}
    />
  );
  return (
    <div className="inner-content-container">
      <Row>
        <Col lg={6} span={24}>
          {chatId ? existingChatParticipantsSection : newChatParticipantsSection}
        </Col>
        <Col lg={{ span: 17, offset: 1 }} span={24}>
          {!isEmpty(metaData) && <Messages metaData={metaData} chatId={chatId} />}
        </Col>
      </Row>
      <Modal
        onCancel={toggleParticipantsModal}
        confirmLoading={modalConfirmLoading}
        onOk={onEditParticipants}
        visible={isParticipantsModalOpen}
        okButtonProps={{ disabled: !newParticipants.length }}>
        {editParticipantsModalContent}
      </Modal>
      <Modal
        onCancel={toggleRenameModal}
        confirmLoading={modalConfirmLoading}
        onOk={onEditGroupName}
        visible={isRenameGroupModalOpen}
        okButtonProps={{ disabled: !newGroupName.length }}>
        {renameGroupModalContent}
      </Modal>
    </div>
  );
};
