import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useTranslations } from '@veraio/strank';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import Localization from 'components/layouts/LocalizationPanel';
import { Icon } from 'components/ui';
import Routes from 'enums/Routes';
import logo from 'assets/images/Logo_white.png';
import {
  logoImage,
  drawerWrapper,
  sidebarContainer,
  translationsSideBarStyles,
  hamburgerMenu,
  logoAndHamburgerStyles,
} from './styles';

const NavigationPanel = () => {
  const { getText } = useTranslations();
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <aside css={drawerWrapper(isSidebarOpen)}>
      <div css={logoAndHamburgerStyles}>
        <button css={hamburgerMenu} onClick={handleToggleSidebar}>
          <i role="presentation" className="las la-bars css-1oxpxkl-Icon" />
        </button>
        <Link to={Routes.HomePage} data-automation-id="index-bc80c8ea-1227-47ee-a1d2-9994341f1fdd">
          <img css={logoImage} src={logo} alt="Logo" />
        </Link>
      </div>
      <div css={sidebarContainer}>
        <Menu mode="inline">
          <Menu.Item key="1" icon={<Icon iconName="las la-th-large" />}>
            <Link to={Routes.HomePage}>{getText('dashboard')}</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<Icon iconName="las la-shopping-bag" />}>
            <Link to={Routes.Deals}>{getText('deals')}</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<Icon iconName="las la-ticket-alt" />}>
            <Link to={Routes.Orders}>{getText('Orders')}</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<Icon iconName="las la-comment-alt" />}>
            <Link to={Routes.Disputes}>{getText('disputes')}</Link>
          </Menu.Item>
          {/* REMOVE TAG: Hide Refunds
          <Menu.Item key="=5" icon={<Icon iconName="las la-euro-sign" />}>
            <Link to={Routes.Refunds}>{getText('refunds')}</Link>
          </Menu.Item> */}
          <Menu.Item key="6" icon={<Icon iconName="las la-wallet" />}>
            <Link to={Routes.Businesses}>{getText('businesses')}</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<Icon iconName="las la-map-marker" size={18} />}>
            <Link to={Routes.Addresses}>{getText('addresses')}</Link>
          </Menu.Item>
          {isAdmin && (
            <Menu.Item key="8" icon={<Icon iconName="las la-shield-alt" size={18} />}>
              <Link to={Routes.Managers}>{getText('managers')}</Link>
            </Menu.Item>
          )}
          {isAdmin && (
            <Menu.Item key="9" icon={<Icon iconName="las la-cog" size={18} />}>
              <Link to={Routes.CountryConfiguration}>{getText('countryConfiguration')}</Link>
            </Menu.Item>
          )}
          <Menu.Item key="10" icon={<Icon iconName="lar la-bell" />}>
            <Link to={Routes.Notifications}>{getText('notifications')}</Link>
          </Menu.Item>
          <Menu.Item key="11" icon={<Icon iconName="las la-chart-pie" size={20} />}>
            <Link to={Routes.Statistics}>{getText('stats')}</Link>
          </Menu.Item>
          {isAdmin && (
            <Menu.Item key="12" icon={<Icon iconName="las la-cog" size={20} />}>
              <Link to={Routes.Settings}>{getText('settings')}</Link>
            </Menu.Item>
          )}
          <Menu.Item key="13" icon={<Icon iconName="las la-globe" />}>
            <Localization />
          </Menu.Item>
          <Menu.Item key="14" icon={<Icon iconName="las la-angle-double-right" />}>
            <a href={applicationUrls.strank} target="_blank" css={translationsSideBarStyles} rel="noreferrer">
              {getText('manageWebsiteTranslations')}
            </a>
          </Menu.Item>
        </Menu>
      </div>
    </aside>
  );
};
NavigationPanel.propTypes = {
  isVisible: PropTypes.bool,
  toggleNavigationDrawer: PropTypes.func,
};
export default NavigationPanel;
