import { theme } from 'styles';

export const businessDetailsWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  paddingBottom: '0.75em',
  '& span': {
    color: theme.blue2,
    marginRight: '0.625em',
  },
  '& .la-globe': {
    fontSize: '1.125rem',
  },
  '& label': {
    color: theme.gray100,
  },
};

export const formContactInfoStyles = {
  alignItems: 'baseline',
  '& .phone-number': {
    minWidth: '7rem'
  }
};

export const phoneCodeStyles = {
  fontSize: '0.875rem',
  lineHeight: '1.5715',
  height: '2rem',
};
