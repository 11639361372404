/* eslint-disable max-len */
import { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import { flatMap, isFunction, isArray, isNull } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Form, Input } from 'antd';
import { Button, Dropdown, Modal } from 'components/UIExternal';
import { USER_TYPE, userTypeInverse } from 'enums';
import { setError, getOneLifeAccounts } from 'services';
import { actionButtons } from './styles';

const emailRegex =
  /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const isValidEmail = (email) => emailRegex.test(email);

export const EditManagerPopup = forwardRef(({ onSubmit }, ref) => {
  const { getText } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const [form] = Form.useForm();
  const modalRef = useRef();
  const [manager, setManager] = useState(null);
  const isAreaManager = manager?.managerType === USER_TYPE.AREA_MANAGER;
  const [oneLifeAccounts, setOneLifeAccounts] = useState(null);
  const [selectedAreas, setSelectedAreas] = useState(null);

  useEffect(() => {
    handleResetModal();
    manager?.id && handleInitUserData();
  }, [manager?.modalId]);

  const handleResetModal = () => {
    form.resetFields();
    oneLifeAccounts && setOneLifeAccounts(null);
  };

  const handleInitUserData = async () => {
    await fetchOneLifeAccounts(manager?.email);
    isAreaManager && handleAreasOptions(manager?.countries?.map((el) => el?.id));
    form.setFieldsValue({
      profileEmail: manager?.email ?? '',
      feeAccountId: manager?.feeAccountId ?? '',
      countries: isAreaManager ? manager?.countries.at(0) : manager?.countries ?? [],
      areas: manager?.areas ?? [],
    });
  };

  useImperativeHandle(ref, () => ({
    open: (data) => {
      modalRef.current.open();
      setManager(data);
    },
    close: () => {
      setManager(null);
      modalRef.current.close();
    },
  }));

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

  const fetchOneLifeAccounts = async (profileEmail) => {
    const [res, err] = await getOneLifeAccounts({ profileEmail, pageIndex: 0, pageSize: 10 });
    err ? setError(err) : setOneLifeAccounts(res.items);
  };

  const handleAreasOptions = (countriesData) =>
    setSelectedAreas(
      flatMap(
        countries?.filter((el) =>
          isArray(countriesData) ? countriesData.includes(el?.id) : el?.id === countriesData?.id,
        ),
        'areas',
      ),
    );

  const handleValueChange = (data) => {
    const { profileEmail, countries: countriesValue } = data;

    switch (true) {
      //Email
      case isValidEmail(profileEmail) && profileEmail?.length > 0:
        return fetchOneLifeAccounts(profileEmail);
      case profileEmail?.length === 0:
        return setOneLifeAccounts(null);
      //Area
      case isAreaManager && !!countriesValue:
        handleAreasOptions(countriesValue);
        return form.setFieldsValue({ areas: [] });
      case isAreaManager && isNull(countriesValue):
        setSelectedAreas(null);
        return form.setFieldsValue({ areas: [] });
    }
  };

  const handleClose = () => {
    modalRef.current.close();
  };

  const handleSubmit = (data) => {
    isFunction(onSubmit) &&
      onSubmit({ ...data, userTypeId: manager?.managerType, ...(manager?.id && { userId: manager?.id }) });
  };

  return (
    <Modal
      ref={modalRef}
      modalTitle={
        manager?.id
          ? getText('editManagerType', { type: getText(userTypeInverse[manager?.managerType]) })
          : getText('addManagerType', { type: getText(userTypeInverse[manager?.managerType]) })
      }>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={handleValueChange}
        onFinish={handleSubmit}
        validateMessages={validateMessages}>
        <Form.Item
          label={getText('enterEmail')}
          name="profileEmail"
          rules={[{ required: true }, { type: 'email' }]}
          required>
          <Input disabled={manager?.id} />
        </Form.Item>

        <Form.Item label={getText('selectAccount')} name="feeAccountId" rules={[{ required: true }]}>
          <Dropdown
            withSearch
            placeholder={getText('selectAccount')}
            options={oneLifeAccounts}
            displayKey="nickName"
            uniqueKey="id"
            mapValue={(el) => el?.id}
          />
        </Form.Item>

        <Form.Item label={getText('selectCountry')} name="countries" rules={[{ required: true }]}>
          <Dropdown
            multiSelect={!isAreaManager}
            withSearch
            placeholder={getText('selectCountry')}
            options={countries}
            displayKey="name"
            uniqueKey="id"
          />
        </Form.Item>
        {isAreaManager && (
          <Form.Item label={getText('selectArea')} name="areas" rules={[{ required: true }]}>
            <Dropdown
              multiSelect
              withSearch
              placeholder={getText('selectArea')}
              options={selectedAreas}
              displayKey="areaName"
              uniqueKey="areaId"
            />
          </Form.Item>
        )}
        <div css={actionButtons}>
          <Button small type="secondary">
            {getText('apply')}
          </Button>
          <Button small type="link" onClick={handleClose}>
            {getText('cancel')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

EditManagerPopup.propTypes = {
  onSubmit: PropTypes.func,
};
