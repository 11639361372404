import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Col, Row, Spin, Modal, Tooltip } from 'antd';
import moment from 'moment';
import { Button, TAG_TYPE } from 'components/UIExternal';
import DeclineModal from 'components/ui/DeclineModal';
import CommentModal from 'components/ui/CommentModal';
import ImageGalleryPanel from 'components/ui/ImageGalleryPanel';
import { Icon } from 'components/ui';
import { Tag } from 'components/UIExternal';
import { disputeStatus, refundStatus, refundStatusColor, refundStatusInverse } from 'enums';
import { formatDefaultDateTime, useParamsInt } from 'utils';
import useError from 'services/errorHandling/useError';
import { getRefundById, updateRefundStatus, addCommentToRefund } from 'services';
import { mb } from 'styles';
import {
  spinner,
  navigationWrapper,
  userDetailsWrapper,
  userDetailsLine,
  subtitle,
  avatar,
  nameDateWrapper,
  descriptionWrapper,
  responsiveImg,
  galleryModal,
  transactionContainer,
  transactionStatus,
  noAvatar,
} from '../DisputeDetailPage/styles';

const RefundDetail = () => {
  const history = useHistory();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const refundId = useParamsInt('refundId');
  const [refund, setRefund] = useState();
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);

  useEffect(() => {
    fetchRefund();
  }, [refundId]);

  const fetchRefund = async () => {
    const [res, err] = await getRefundById(refundId);
    err ? setError(err) : setRefund(res);
  };

  const handleEditRefundStatus = async (status, reason) => {
    const [, err] = await updateRefundStatus({ id: refundId, status, reason });
    err ? setError(err) : fetchRefund();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToRefund = async (comment) => {
    const [, err] = await addCommentToRefund({ refundId, comment }, setError);
    err ? setError(err) : fetchRefund();
    setIsCommentModalVisible(false);
  };

  return refund ? (
    <>
      <div className="inner-content-container">
        <div css={navigationWrapper}>
          <div className="specs-container">
            <Button type="link" onClick={() => history.goBack()} small>
              <Icon iconName="las la-arrow-left" />
            </Button>

            <h2 className="flex flex-center deal-name">{getDynamicTranslation(refund.dealTitle)?.title}</h2>
            <Tag type={refundStatusColor[refund.statusId]}>{getText(refundStatusInverse[refund.statusId])}</Tag>
          </div>

          <div className="actions-container">
            {refund.statusId === disputeStatus.pending && (
              <Button
                type="secondary"
                small
                onClick={() => handleEditRefundStatus(refundStatus.approved)}
                disabled={refund.exchangeTransaction.status === 'pending'}>
                {getText('approveRefund')}
              </Button>
            )}
            <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
              {getText('comment')}
            </Button>
            {refund.statusId === disputeStatus.pending && (
              <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
                {getText('declineRefund')}
              </Button>
            )}
          </div>
        </div>
        {refund.comments?.length > 0 && (
          <Row css={mb({ lg: 20, span: 30 })}>
            {refund.comments.map((comment, ind) => (
              <Col span={24} key={ind}>
                <p>
                  <span className="bolder-text">{`${comment.managerName} [${formatDefaultDateTime(
                    comment.createdDate,
                  )}]: `}</span>
                  {comment.comment}
                </p>
              </Col>
            ))}
          </Row>
        )}
        <Row css={userDetailsLine}>
          <Col span={24} lg={5}>
            <div css={userDetailsWrapper}>
              <Icon iconName="las la-shopping-bag" />
              <label>{refund.userNames}</label>
            </div>
          </Col>
          <Col span={24} lg={4}>
            <div css={userDetailsWrapper}>
              <Icon iconName="las la-phone-alt" />
              <label>{refund.phone || '-'}</label>
            </div>
          </Col>
          <Col span={24} lg={15}>
            <div css={userDetailsWrapper}>
              <Icon iconName="las la-envelope" />
              <label>{refund.email || '-'}</label>
            </div>
          </Col>
        </Row>
        <Row css={mb({ lg: 27, span: 25 })}>
          <Col span={24}>
            <label css={subtitle}>{getText('buyer')}</label>
          </Col>
          <Col span={4} lg={1}>
            {refund.reviewUserAvatar ? (
              <img src={refund.userAvatar} alt={refund.userNames} css={avatar} />
            ) : (
              <Icon iconName="las la-user" size={40} css={noAvatar} />
            )}
          </Col>
          <Col span={20} lg={5}>
            <div css={nameDateWrapper}>
              <p>{refund.userNames}</p>
              <label>{formatDefaultDateTime(refund.createdDate)}</label>
            </div>
          </Col>
          <Col span={12} lg={6}>
            <div css={descriptionWrapper}>
              <label>{refund.title}</label>
              <p>{refund.description}</p>
              <div className="flex">
                {refund.media &&
                  refund.media.length > 0 &&
                  refund.media.map(
                    (item, ind) =>
                      item.url && (
                        <Button
                          key={ind}
                          type="link"
                          small
                          onClick={() => setIsImageModalVisible(true)}
                          css={responsiveImg}>
                          <img src={item.url} alt="Refund" />
                        </Button>
                      ),
                  )}
              </div>
            </div>
          </Col>
          <Col span={12} lg={12}>
            {refund.exchangeTransaction && (
              <>
                <h3>{getText('transactionInformation')}</h3>
                <div css={transactionContainer}>
                  <p>
                    {getText('transactionId')} <span>{refund.exchangeTransaction.exchangeTransactionId}</span>
                  </p>
                  <p>
                    {getText('date')}:{' '}
                    <span>{moment(refund.exchangeTransaction.approvedOn).format('DD/MM/YYYY, HH:mm:ss')}</span>
                  </p>
                  <div css={transactionStatus}>
                    {getText('status')}:
                    <Tag
                      type={refund.exchangeTransaction.status === 'pending' ? TAG_TYPE.WARNING : TAG_TYPE.GREEN}
                      marginLeft="1em">
                      {getText(refund.exchangeTransaction.status)}
                    </Tag>
                    {refund.exchangeTransaction.status === 'pending' && (
                      <Tooltip placement="right" title={getText('pendingMeansCouponProcessing')}>
                        {/* We need this div because Popover puts ref to its only child to append onClick method which opens the popup
        But our icon do not accept ref and it is not acceptable to have ref on icon, so we just add parent span */}
                        <span>
                          <Icon iconName="las la-question-circle" size={20} />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </div>
      <DeclineModal
        title={getText('refund')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditRefundStatus(refundStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToRefund}
      />
      <Modal
        wrapClassName={galleryModal}
        visible={isImageModalVisible}
        footer={null}
        destroyOnClose
        width={1300}
        onCancel={() => setIsImageModalVisible(false)}>
        <ImageGalleryPanel
          showNav
          items={refund.media?.map((item) => item.url && { thumbnail: item.thumbnailUrl, original: item.url })}
        />
      </Modal>
    </>
  ) : (
    <Spin size="large" css={spinner} />
  );
};

export default RefundDetail;
