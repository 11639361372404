export default {
  // private routes
  Deals: '/deals',
  Orders: '/orders',
  Businesses: '/businesses',
  Addresses: '/addresses',
  Refunds: '/refunds',
  Disputes: '/disputes',
  DealDetail: '/deal/:dealId',
  Managers: '/managers',
  DisputeDetail: '/dispute/:disputeId',
  RefundDetail: '/refund/:refundId',
  AddressDetail: '/address/:addressId',
  BusinessDetail: '/business/:businessId',
  Messages: '/messages',
  StartChat: '/messages/start-chat',
  OngoingChat: '/messages/chat',
  Settings: '/settings',
  CountryConfiguration: '/country-configuration',
  Notifications: '/notifications',
  CreateNotification: '/notifications/create',
  NotificationDetail: '/notifications/:notificationId',
  Statistics: '/statistics',

  // public routes
  RedirectCallback: '/authorization-callback',
  Logout: '/logout',
  HomePage: '/',
};
