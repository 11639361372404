import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Collapse } from 'antd';
import { useUrlParams } from 'components/UIExternal';
import { TextInput, CountryDropdown } from 'components/ui';
import { pageFilterCollapse } from 'styles';

export const ManagersFilterBar = ({ paginationRef, filterKeys }) => {
  const { queryParams } = useUrlParams();
  const { getText } = useTranslations();

  return (
    <Collapse css={pageFilterCollapse}>
      <Collapse.Panel header={getText('filters')}>
        <section className="filters-container">
          <div>
            <span>{getText('name')}</span>
            <TextInput
              onPressEnter={(e) => paginationRef.current?.applyFilters({ [filterKeys.name]: e.target.value })}
              placeholder={getText('searchByName')}
              defaultValue={queryParams[filterKeys.name]}
            />
          </div>
          <div>
            <span>{getText('email')}</span>
            <TextInput
              onPressEnter={(e) => paginationRef.current?.applyFilters({ [filterKeys.email]: e.target.value })}
              placeholder={getText('searchByEmail')}
              defaultValue={queryParams[filterKeys.email]}
            />
          </div>
          <div>
            <span>{getText('country')}</span>
            <CountryDropdown
              placeholder={getText('searchByCountry')}
              value={queryParams[filterKeys.countryId]}
              onChange={(val) => paginationRef.current?.applyFilters({ [filterKeys.countryId]: val })}
            />
          </div>
        </section>
      </Collapse.Panel>
    </Collapse>
  );
};

ManagersFilterBar.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
};
