import PropTypes from 'prop-types';
import { Modal, Radio } from 'antd';
import { pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { changeUserCurrency, useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  popup,
  popupContent,
  columnContainer,
  columnTitle,
  columnListOptionsContainer,
  verticalSeparator,
  languageOption,
} from './styles';

const LocalizationPopup = ({ open, closePopup }) => {
  const { selectedCurrency: currency, fiatCurrencies } = useCurrencies((currenciesState) =>
    pick(currenciesState, ['selectedCurrency', 'fiatCurrencies']),
  );
  const { getText, language, allEnvLanguages, changeLanguage } = useTranslations();

  const switchLanguage = (newLanguage) => {
    changeLanguage(newLanguage);
    closePopup();
  };

  const switchCurrency = (value) => {
    changeUserCurrency(fiatCurrencies?.find((el) => el.code === value));
    closePopup();
  };

  return (
    <Modal visible={open} zIndex={1200} onCancel={closePopup} bodyStyle={popupContent} css={popup} footer={null}>
      <section css={columnContainer}>
        <h4 css={columnTitle}>{getText('language')}</h4>
        <Radio.Group
          defaultValue={language?.code}
          buttonStyle="solid"
          onChange={(e) => switchLanguage(e.target.value)}
          css={columnListOptionsContainer}>
          {allEnvLanguages?.map((item) => (
            <Radio.Button key={item.code} value={item.code} css={languageOption}>
              {item.nativeName}
            </Radio.Button>
          ))}
        </Radio.Group>
      </section>
      <span css={verticalSeparator} />

      <section css={columnContainer}>
        <h4 css={columnTitle}>{getText('currency')}</h4>
        <Radio.Group defaultValue={currency?.code} buttonStyle="solid" css={columnListOptionsContainer}>
          {fiatCurrencies?.map((item) => (
            <Radio.Button
              key={item?.code}
              onChange={(e) => switchCurrency(e.target.value)}
              checked={item?.code === currency?.code}
              value={item?.code}>
              {`${item?.symbol} ${item?.code}`}
            </Radio.Button>
          ))}
        </Radio.Group>
      </section>
    </Modal>
  );
};

LocalizationPopup.propTypes = {
  open: PropTypes.bool,
  closePopup: PropTypes.func,
};

export default LocalizationPopup;
