import { getReq, patchReq, postReq, deleteReq } from 'services/axios/makeRequest';
import {
  getAllManagersParams,
  getAllManagersResponse,
  getOneLifeAccountsParams,
  updateManagersBody,
  createManagerBody,
} from 'services/models/managers';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/Managers`;

export const getAllManagers = async (options) => {
  const [res, err] = await getReq(`${baseUrl}/List?${getAllManagersParams(options)}`);
  return [res && getAllManagersResponse(res), err];
};

export const getCountryManagersCountries = () => getReq(`${baseUrl}/MyCountries`);

export const getOneLifeAccounts = (options) =>
  getReq(`${apiUrls.oneLife}/profile/accounts/listAll?${getOneLifeAccountsParams(options)}`);

export const updateManager = (options) =>
  patchReq(`${baseUrl}/Countries/${options.userId}`, updateManagersBody(options));

export const createManager = (options) => postReq(`${baseUrl}/Create`, createManagerBody(options));

export const deleteManager = (userId) => deleteReq(`${baseUrl}/Delete/${userId}`);
