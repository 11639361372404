import { theme } from 'styles';

export const spinner = {
  margin: '1.875em 1.875em !important',
};

export const businessDetailsWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  paddingBottom: '0.75em',
  '& span': {
    color: theme.blue2,
    marginRight: '0.625em',
  },
  '& .la-globe': {
    fontSize: '1.125rem',
  },
  '& label': {
    color: theme.gray100,
  },
};

export const businessDetailsLine = {
  display: 'flex',
  alignItems: 'center',

  '& label': { textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '12.5rem', whiteSpace: 'nowrap' },
  [theme.Desktop]: {
    borderBottom: `1px solid ${theme.gray500}`,
  },
};

export const subTitle = {
  display: 'inline-block',
  color: theme.background100,
  fontSize: '1rem',
  fontWeight: 700,
  marginBottom: '0.625em',
};

export const informationWrapper = {
  borderBottom: `1px solid ${theme.gray500}`,
  '& label': {
    display: 'inline-block',
    color: theme.background200,
    marginBottom: '0.625em',
  },
  '& p': {
    marginBottom: '0.625em',
  },
  '& .ant-tabs-top > .ant-tabs-nav::before': {
    borderBottom: 'none',
  },
  '& .ant-tabs-nav-wrap': {
    marginBottom: '0.625em',
  },
  [theme.Desktop]: {
    paddingLeft: '1em',
  },
};

export const specificBusinessInfoWrapper = {
  '& label': {
    display: 'inline-block',
    color: theme.background200,
    marginBottom: '0.625em',
  },
  '& p': {
    marginBottom: '0.625em',
  },
  '& .ant-tabs-top > .ant-tabs-nav::before': {
    borderBottom: 'none',
  },
  '& .ant-tabs-nav-wrap': {
    marginBottom: '0.625em',
  },
  [theme.Desktop]: {
    paddingLeft: '1em',
  },
};
