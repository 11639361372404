import { useState } from 'react';
import { Button, Tag } from 'components/UIExternal';
import { useTranslations } from '@veraio/strank';
import { isEmpty } from 'lodash-es';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Icon, UploadedFiles } from 'components/ui';
import {
  BusinessMainInformationPreview,
  BusinessAdditionalInformationPreview,
  BusinessAddressPreview,
  ContactInformationPreview,
  BusinessMainInformationEdit,
  BusinessAdditionalInformationEdit,
  BusinessAddressEdit,
  ContactInformationEdit,
} from 'components/screens/BusinessDetailPage';
import { businessStatus, businessStatusColor, businessStatusInverse } from 'enums';
import { formatDefaultDateTime } from 'utils';
import { mb } from 'styles';
import { informationWrapper, subTitle, specificBusinessInfoWrapper } from '../styles';
import { navigationWrapper, editFormSectionBtn } from './styles';

export const MainInformation = ({
  business,
  handleEditBusinessStatus,
  setIsCommentModalVisible,
  setIsDeclineModalVisible,
  handleEditBusiness,
  comments,
}) => {
  const { getText, getDynamicTranslation } = useTranslations();
  const history = useHistory();
  //states for editing business forms
  const [isEditingMainInfo, setIsEditingMainInfo] = useState(false);
  const [isEditingAdditionalInfo, setIsEditingAdditionalInfo] = useState(false);
  const [isEditingBusinessAddress, setIsEditingBusinessAddress] = useState(false);
  const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);

  const handleEditMainInformation = () => {
    setIsEditingMainInfo((prev) => !prev);
  };

  const handleEditBusinessAddress = () => {
    setIsEditingBusinessAddress((prev) => !prev);
  };

  const handleEditAdditionalInformation = () => {
    setIsEditingAdditionalInfo((prev) => !prev);
  };

  const handleEditContactInformation = () => {
    setIsEditingContactInfo((prev) => !prev);
  };

  return (
    <>
      <div css={navigationWrapper}>
        <div className="specs-container">
          <Button type="link" onClick={() => history.goBack()} small>
            <Icon iconName="las la-arrow-left" />
          </Button>

          <label className="flex flex-center business-name">{getDynamicTranslation(business?.name)?.name}</label>
          <Tag type={businessStatusColor[business?.statusId]}>{getText(businessStatusInverse[business?.statusId])}</Tag>
        </div>
        <div className="actions-container">
          {business?.statusId !== businessStatus.approved && (
            <Button type="secondary" small onClick={() => handleEditBusinessStatus(businessStatus.approved)}>
              {business?.statusId === businessStatus.edited ? getText('approveChanges') : getText('verifyBusiness')}
            </Button>
          )}

          <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
            {getText('comment')}
          </Button>

          {business?.statusId !== businessStatus.declined && (
            <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
              {business?.statusId === businessStatus.edited ? getText('declineChanges') : getText('declineBusiness')}
            </Button>
          )}
        </div>
      </div>
      {!!comments?.length && (
        <Row css={mb({ lg: 20, span: 30 })}>
          {comments.map((comment, ind) => (
            <Col span={24} key={ind}>
              <p>
                <span className="bolder-text">{`${comment.managerName} [${formatDefaultDateTime(
                  comment.createdDate,
                )}]: `}</span>
                {comment.comment}
              </p>
            </Col>
          ))}
        </Row>
      )}

      {/* here starts the business components */}
      <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>
            {business?.statusId === businessStatus.edited
              ? getText('editedMainInformation')
              : getText('mainInformation')}
          </label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditMainInformation}>
            {isEditingMainInfo ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingMainInfo ? (
          <BusinessMainInformationEdit
            business={business}
            onCancel={() => setIsEditingMainInfo(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <BusinessMainInformationPreview business={business} />
        )}
      </Row>
      <Row css={[specificBusinessInfoWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('additionalInformation')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditAdditionalInformation}>
            {isEditingAdditionalInfo ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingAdditionalInfo ? (
          <BusinessAdditionalInformationEdit
            business={business}
            onCancel={() => setIsEditingAdditionalInfo(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <BusinessAdditionalInformationPreview business={business} />
        )}
      </Row>
      <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('contactInformation')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditContactInformation}>
            {isEditingContactInfo ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingContactInfo ? (
          <ContactInformationEdit
            business={business}
            onCancel={() => setIsEditingContactInfo(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <ContactInformationPreview business={business} />
        )}
      </Row>
      <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('businessAddress')}</label>
          <Button type="default" css={editFormSectionBtn} onClick={handleEditBusinessAddress}>
            {isEditingBusinessAddress ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        </Col>
        {isEditingBusinessAddress ? (
          <BusinessAddressEdit
            business={business}
            onCancel={() => setIsEditingBusinessAddress(false)}
            onEdit={handleEditBusiness}
          />
        ) : (
          <BusinessAddressPreview business={business} />
        )}
      </Row>
      {!isEmpty(business.media) && (
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('uploadedFiles')}</label>
          </Col>
          <UploadedFiles media={business.media} css={mb({ lg: 10 })} />
        </Row>
      )}
    </>
  );
};
