import PropTypes from 'prop-types';
import { Col } from 'antd';
import { Icon } from 'components/ui';
import { businessDetailsWrapper } from './styles';

const ContactInformationPreview = ({ business }) => {
  return (
    <>
      <Col span={24} lg={4}>
        <div css={businessDetailsWrapper}>
          <Icon iconName="las la-phone-alt" />
          <label title={business?.phone}>+{business?.phone}</label>
        </div>
      </Col>
      <Col span={24} lg={6}>
        <div css={businessDetailsWrapper}>
          <Icon iconName="las la-envelope" />
          <label title={business?.email}>{business?.email}</label>
        </div>
      </Col>
      {business?.website && (
        <Col span={24} lg={9}>
          <div css={businessDetailsWrapper}>
            <Icon iconName="las la-globe" />
            <label title={business?.website}>{business?.website}</label>
          </div>
        </Col>
      )}
    </>
  );
};

ContactInformationPreview.propTypes = {
  deal: PropTypes.object,
  business: PropTypes.object,
};

export default ContactInformationPreview;
