import { useState, useEffect } from 'react';
import { pick } from '@veraio/core';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { MobileVersionConfiguration, WalletsFeeConfiguration, FormBox } from 'components/screens';
import useError from 'services/errorHandling/useError';
import { getAllConfigs, updateConfig } from 'services';
import { MOBILE_VERSION, CONFIG_GROUP, WALLETS_FEE } from 'enums';
import { configurationsContainer } from './styles';

export const GeneralConfigurations = () => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [configurations, setConfigurations] = useState(null);

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const fetchConfigurations = async () => {
    const request = getAllConfigs;
    const params = [];
    const [res, err] = await request(...params);
    if (err) return setError(err);

    setConfigurations({
      [CONFIG_GROUP.MOBILE_VERSION]: pick(res, Object.values(MOBILE_VERSION)),
      [CONFIG_GROUP.WALLETS_FEE]: pick(res, Object.values(WALLETS_FEE)),
    });
  };

  const updateConfigs = async (groupName, data) => {
    const request = updateConfig;
    const params = [groupName, data].concat([]);
    const [, err] = await request(...params);
    if (err) return setError(err);

    toast.success(getText('successfullyEditedConfig'));
    fetchConfigurations();
  };

  const configProps = (groupName) => ({
    groupName,
    data: configurations?.[groupName],
    onUpdate: (data) => updateConfigs(groupName, data),
  });

  return (
    <div css={configurationsContainer}>
      <FormBox title={getText('mobileVersion')}>
        <MobileVersionConfiguration {...configProps(CONFIG_GROUP.MOBILE_VERSION)} />
      </FormBox>
      <FormBox title={getText('Wallets')}>
        <WalletsFeeConfiguration {...configProps(CONFIG_GROUP.WALLETS_FEE)} />
      </FormBox>
    </div>
  );
};

GeneralConfigurations.propTypes = {
  fee: PropTypes.number,
  feeName: PropTypes.string,
  currency: PropTypes.string,
  onChangeFee: PropTypes.func,
  onSaveFee: PropTypes.func,
};
