import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Collapse } from 'antd';
import { TextInput, CountryDropdown, DatePicker } from 'components/ui';
import { useUrlParams } from 'components/UIExternal';
import { pageFilterCollapse } from 'styles';

export const OrderFilters = ({ onChange, filterKeys }) => {
  const { getText } = useTranslations();
  const { queryParams } = useUrlParams();

  return (
    <Collapse css={pageFilterCollapse}>
      <Collapse.Panel header={getText('filters')}>
        <section className="filters-container">
          <div>
            <label>
              {getText('merchant')} {getText('country')}
            </label>
            <CountryDropdown
              placeholder={getText('country')}
              value={queryParams?.merchantCountryId}
              onChange={(val) => onChange({ [filterKeys.merchantCountryId]: val })}
            />
          </div>
          <div>
            <label>
              {getText('merchant')} {getText('business')} {getText('email')}
            </label>
            <TextInput
              placeholder={getText('email')}
              defaultValue={queryParams?.merchantEmail}
              onPressEnter={(e) => onChange({ [filterKeys.merchantEmail]: e.target.value })}
            />
          </div>
          <div>
            <label>
              {getText('merchant')} {getText('business')} {getText('name')}
            </label>
            <TextInput
              placeholder={getText('business')}
              defaultValue={queryParams?.merchantName}
              onPressEnter={(e) => onChange({ [filterKeys.merchantName]: e.target.value })}
            />
          </div>
          <div>
            <label>
              {getText('buyer')} {getText('country')}
            </label>
            <CountryDropdown
              placeholder={getText('country')}
              value={queryParams?.buyerCountryId}
              onChange={(val) => onChange({ [filterKeys.buyerCountryId]: val })}
            />
          </div>
          <div>
            <label>
              {getText('buyer')} {getText('email')}
            </label>
            <TextInput
              placeholder={getText('email')}
              defaultValue={queryParams?.buyerEmail}
              onPressEnter={(e) => onChange({ [filterKeys.buyerEmail]: e.target.value })}
            />
          </div>
          <div className="flex-auto">
            <label>{getText('from')}</label>
            <DatePicker
              defaultValue={queryParams?.dateFrom}
              onChange={(val) => onChange({ [filterKeys.dateFrom]: val })}
            />
          </div>
          <div className="flex-auto">
            <label>{getText('to')}</label>
            <DatePicker defaultValue={queryParams?.dateTo} onChange={(val) => onChange({ [filterKeys.dateTo]: val })} />
          </div>
          <div>
            <label>{getText('orderId')}</label>
            <TextInput
              placeholder={getText('searchByOrderId')}
              onPressEnter={(e) => onChange({ [filterKeys.orderId]: e.target.value })}
            />
          </div>
        </section>
      </Collapse.Panel>
    </Collapse>
  );
};

OrderFilters.propTypes = {
  onChange: PropTypes.func,
  filterKeys: PropTypes.object,
};
