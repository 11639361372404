import { getText } from '@veraio/strank';
import moment from 'moment';
import { Tag } from 'components/UIExternal';
import { dealStatusColor, dealStatusInverse } from 'enums';

export const columns = () => [
  {
    title: getText('changedAt'),
    dataIndex: 'createdDate',
    render: (createdDate) => <span>{moment(createdDate).format('DD/MM/YYYY h:mm:ss')}</span>,
    width: 100,
  },
  {
    title: getText('changedBy'),
    dataIndex: 'createdBy',
    width: 120,
  },
  {
    title: getText('reason'),
    dataIndex: 'reason',
    width: 120,
  },
  {
    title: getText('status'),
    dataIndex: 'statusId',
    render: (statusId) => <Tag type={dealStatusColor[statusId]}>{getText(dealStatusInverse[statusId])}</Tag>,
    width: 100,
  },
];
