import { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, pick } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Form, Input } from 'antd';
import { formStyles } from './styles';
import { FormEditButtons } from '../../../shared';
import { Dropdown } from '../../../UIExternal';

const BusinessAddressEdit = ({ business, onEdit, onCancel }) => {
  const { getText } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const [form] = Form.useForm();
  const [areas, setAreas] = useState(countries.find((country) => country?.name === business?.country)?.areas ?? []);

  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };

  const handleValueChange = (changedValues) => {
    if (!isEmpty(changedValues?.country)) {
      setAreas(changedValues?.country?.areas);
      form.resetFields(['areaId']);
    }
  };

  const newInitialValues = {
    ...pick(business, 'country', 'areaId', 'state', 'city', 'street', 'postcode'),
  };

  const handleSaveForm = async (values) => {
    await onEdit(
      isEmpty(
        countries.find((country) => country?.name === values?.country?.name || country?.name === values?.country)
          ?.areas,
      )
        ? { ...values, areaId: null }
        : values,
      ['country', 'areaId', 'state', 'city', 'street', 'postcode'],
    );
    onCancel();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={newInitialValues}
      onFinish={handleSaveForm}
      css={formStyles}
      onValuesChange={(changedValues, formValues) => {
        handleValueChange(changedValues, formValues);
      }}>
      <Form.Item label={getText('country')} name="country" rules={[mandatoryRule]} style={{ width: '40%' }}>
        <Dropdown
          noClear
          small
          placeholder={getText('selectCountry')}
          options={countries}
          uniqueKey="name"
          displayKey="name"
          withSearch
        />
      </Form.Item>
      <Form.Item label={getText('area')} name="areaId" style={{ width: '40%' }}>
        <Dropdown
          small
          placeholder={getText('area')}
          options={areas}
          displayKey="areaName"
          uniqueKey="areaId"
          mapValue={(el) => el?.areaId}
        />
      </Form.Item>
      <Form.Item label={getText('stateProvince')} name="state" style={{ width: '40%' }}>
        <Input type="text" placeholder={getText('selectProvince')} />
      </Form.Item>
      <Form.Item
        label={getText('city')}
        name="city"
        rules={[
          mandatoryRule,
          {
            max: 80,
            message: getText('fieldShouldNotExceedCountSymbols', { field: getText('city'), count: 64 }),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || !/\d/.test(getFieldValue('city'))) return Promise.resolve();

              return Promise.reject(new Error(getText('enterValidCity')));
            },
          }),
        ]}
        style={{ width: '40%' }}>
        <Input type="text" placeholder={getText('enterCity')} />
      </Form.Item>
      <Form.Item
        label={getText('postcode')}
        name="postcode"
        data-id="business-postcode"
        rules={[mandatoryRule]}
        labelAlign="left"
        style={{ width: '40%' }}>
        <Input type="number" placeholder={getText('postcode')} />
      </Form.Item>
      <Form.Item
        label={getText('addressLine')}
        name="street"
        rules={[
          mandatoryRule,
          {
            max: 400,
            message: getText('fieldShouldNotExceedCountSymbols', {
              field: getText('addressLine'),
              count: 400,
            }),
          },
        ]}
        style={{ width: '40%' }}>
        <Input type="text" placeholder={getText('merchantAddress')} />
      </Form.Item>

      <FormEditButtons onCancel={onCancel} />
    </Form>
  );
};

BusinessAddressEdit.propTypes = {
  business: PropTypes.object,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default BusinessAddressEdit;
