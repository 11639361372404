import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { isMobile } from 'react-device-detect';
import { omit, isString } from 'lodash-es';
import { useTranslations, getLanguageByCode } from '@veraio/strank';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { Col, Row, Spin } from 'antd';
import { TabPane, Tabs, Icon } from 'components/ui';
import DeclineModal from 'components/ui/DeclineModal';
import CommentModal from 'components/ui/CommentModal';
import { MainInformation } from './MainInformation';
import { AddressCard } from 'components/shared';
import { businessStatus, businessTypeInverse } from 'enums';
import { useParamsInt } from 'utils';
import { getBusinessById, updateBusinessStatus, addCommentToBusiness, updateBusinessDetails, setError } from 'services';
import { mb } from 'styles';
import {
  spinner,
  businessDetailsLine,
  businessDetailsWrapper,
  informationWrapper,
  subTitle,
  specificBusinessInfoWrapper,
} from './styles';

const BusinessDetail = () => {
  const { getText } = useTranslations();
  const businessId = useParamsInt('businessId');
  const countries = useLocations((locationsState) => locationsState.countries);
  const [business, setBusiness] = useState();
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const isEdited = business?.currentBusinessInformation?.statusId === businessStatus.edited;
  const businessData = isEdited ? business?.newBusinessInformation : business?.currentBusinessInformation;

  useEffect(() => {
    fetchBusinessDetails();
  }, [businessId]);

  const fetchBusinessDetails = async () => {
    const [res, err] = await getBusinessById(businessId);
    err ? setError(err) : setBusiness(res);
  };

  const handleEditBusinessStatus = async (status, reason) => {
    const [, err] = await updateBusinessStatus({ id: businessId, status, reason });
    err ? setError(err) : fetchBusinessDetails();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToBusiness = async (comment) => {
    const [, err] = await addCommentToBusiness({ id: businessId, comment });
    err ? setError(err) : fetchBusinessDetails();
    setIsCommentModalVisible(false);
  };

  const handleEditBusiness = async (values, keysToOmit) => {
    const requestData = {
      ...omit(businessData, keysToOmit),
      ...values,
    };

    const body = {
      ...omit(
        requestData,
        'acceptBankTransfers',
        'acceptCryptoPayment',
        'area',
        'country',
        'bankName',
        'bitpayWalletAddress',
        'coinbaseWalletAddress',
        'iban',
        'media',
        'preferredCurrencyId',
        'name',
        'statusId',
        'typeId',
      ),

      countryId: isString(requestData?.country)
        ? countries.find((country) => country.name === requestData.country)?.id
        : requestData?.country?.id,
      merchantTypeId: requestData?.typeId,
    };

    const [, err] = await updateBusinessDetails(business?.id, body);
    if (err) return setError(err);
    toast.success(getText('successfullyEditedBusiness'));
    await fetchBusinessDetails();
  };

  const mainInformation = {
    handleEditBusinessStatus,
    setIsCommentModalVisible,
    setIsDeclineModalVisible,
    handleEditBusiness,
  };

  return business ? (
    <>
      <div className="inner-content-container">
        {/* main information goes below */}
        <MainInformation {...mainInformation} business={businessData} comments={business?.comments} />

        {/* edited information goes below */}
        {isEdited && (
          <>
            <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
              <Col lg={24} span={24}>
                <label css={subTitle}>{isEdited ? getText('mainInformation') : getText('editedMainInformation')}</label>
              </Col>
              <Col lg={24} span={24}>
                <Tabs>
                  {business?.currentBusinessInformation?.businessDetails.map((detail) => (
                    <TabPane key={detail.languageCode} tab={getLanguageByCode(detail?.languageCode)?.name ?? 'English'}>
                      <Row>
                        <Col lg={4} span={24}>
                          <label>{getText('name')}</label>
                        </Col>
                        <Col lg={20} span={24}>
                          <p>{detail?.name}</p>
                        </Col>
                        <Col lg={4} span={24}>
                          <label>{getText('description')}</label>
                        </Col>
                        <Col lg={20} span={24}>
                          <p>{detail?.description}</p>
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
                </Tabs>
              </Col>
            </Row>
            <Row css={[specificBusinessInfoWrapper, mb({ lg: 36, span: 30 })]}>
              <Col lg={4} span={24}>
                <label>{getText('dealsRunOnDialShaker')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>{business?.currentBusinessInformation?.intendedDeals}</p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('companyRegistrationNumber')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>
                  {business?.currentBusinessInformation?.registrationNumber
                    ? business?.currentBusinessInformation?.registrationNumber
                    : '-'}
                </p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('vatNumber')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>
                  {business?.currentBusinessInformation?.vatNumber
                    ? business?.currentBusinessInformation?.vatNumber
                    : '-'}
                </p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('currency')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>{business?.currentBusinessInformation?.currencyCode}</p>
              </Col>
            </Row>
            <Row css={businessDetailsLine}>
              <Col span={24} lg={5}>
                <div css={businessDetailsWrapper}>
                  <Icon iconName="las la-shopping-bag" />
                  <label>{getText(businessTypeInverse[business?.currentBusinessInformation?.typeId])}</label>
                </div>
              </Col>
              <Col span={24} lg={4}>
                <div css={businessDetailsWrapper}>
                  <Icon iconName="las la-phone-alt" />
                  <label>{business?.currentBusinessInformation?.phone}</label>
                </div>
              </Col>
              <Col span={24} lg={6}>
                <div css={businessDetailsWrapper}>
                  <Icon iconName="las la-envelope" />
                  <label>{business?.currentBusinessInformation?.email}</label>
                </div>
              </Col>
              {business?.currentBusinessInformation?.website && (
                <Col span={24} lg={9}>
                  <div css={businessDetailsWrapper}>
                    <Icon iconName="las la-globe" />
                    <label>{business?.currentBusinessInformation?.website}</label>
                  </div>
                </Col>
              )}
            </Row>
            <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
              <Col lg={24} span={24}>
                <label css={subTitle}>{getText('businessAddress')}</label>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('country')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>{business?.currentBusinessInformation?.country}</p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('stateProvince')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>{business?.currentBusinessInformation?.state ? business?.currentBusinessInformation?.state : '-'}</p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('city')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>{business?.currentBusinessInformation?.city ? business?.currentBusinessInformation?.city : '-'}</p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('postcode')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>
                  {business?.currentBusinessInformation?.postcode
                    ? business?.currentBusinessInformation?.postcode
                    : '-'}
                </p>
              </Col>
              <Col lg={4} span={24}>
                <label>{getText('addressLine')}</label>
              </Col>
              <Col lg={20} span={24}>
                <p>
                  {business?.currentBusinessInformation?.street ? business?.currentBusinessInformation?.street : '-'}
                </p>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col lg={24} span={24} style={{ paddingLeft: !isMobile && 16 }}>
            <label css={subTitle}>{getText('redemptionLocations')}</label>
          </Col>
        </Row>
        <Row css={mb({ lg: 30, span: 20 })} gutter={[24, 24]}>
          {business?.businessAddresses?.map((address, ind) => (
            <Col className="gutter-row" lg={8} span={24} key={ind}>
              <AddressCard address={address} editable={false} />
            </Col>
          ))}
        </Row>
      </div>
      <DeclineModal
        title={getText('business')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditBusinessStatus(businessStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToBusiness}
      />
    </>
  ) : (
    <Spin size="large" css={spinner} />
  );
};

export default BusinessDetail;
