import { pick, isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { printPrice, DEFAULT_FIAT_CURRENCY, DEFAULT_CRYPTO_CURRENCY } from '@oneecosystem/dealshaker-core';
import { Skeleton, Tag } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { useOrders, selectedOrderPaymentDetails } from 'stores';
import { paymentStatusColor, paymentStatusInverse, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { paymentTypeBoxesConfig } from './config';
import { orderDetailsPaymentContainer } from './styles';

export const OrderDetailsPayment = () => {
  const { getText } = useTranslations();
  const { payment, totalPriceCrypto, totalPriceFiat, currencyCode } = useOrders((ordersState) => ({
    ...pick(ordersState?.selectedOrder, ['totalPriceCrypto', 'totalPriceFiat', 'currencyCode', 'payment']),
  }));
  const { isFiatManualPayment } = selectedOrderPaymentDetails();
  const cashConfig = paymentTypeBoxesConfig[payment?.cashPaymentMethodId];
  const cryptoConfig = paymentTypeBoxesConfig[payment?.cryptoPaymentMethodId];
  const printPriceProps = {
    fiatOriginCurrency: currencyCode,
    cryptoDesiredCurrency: (PAYMENT_METHODS_CURRENCIES[payment?.cryptoPaymentMethodId] ?? DEFAULT_CRYPTO_CURRENCY)
      ?.code,
  };

  return (
    <>
      <h3 className="order-details-section-title">{getText('payment')}</h3>
      <section css={orderDetailsPaymentContainer} className="order-details-section">
        <div className="order-details-payment-info-row">
          <p className="gray order-details-payment-info-label">{getText('type')}</p>
          {isNil(payment) ? (
            <Skeleton width="7rem" />
          ) : (
            <span>
              {getText(cashConfig?.title)} + {getText(cryptoConfig?.title)}
            </span>
          )}
        </div>

        <div className="order-details-payment-info-row">
          <p className="gray order-details-payment-info-label">{getText('totalAmount')}</p>
          {isNil(payment) ? (
            <Skeleton width="5rem" />
          ) : (
            <div>
              <p>
                {printPrice({
                  fiat: totalPriceFiat,
                  fiatDesiredCurrency: currencyCode,
                  crypto: totalPriceCrypto,
                  ...printPriceProps,
                })}
              </p>
              {currencyCode !== DEFAULT_FIAT_CURRENCY.code && !isFiatManualPayment && (
                <p>
                  {printPrice({
                    fiat: totalPriceFiat,
                    fiatDesiredCurrency: DEFAULT_FIAT_CURRENCY.code,
                    crypto: totalPriceCrypto,
                    ...printPriceProps,
                  })}
                </p>
              )}
            </div>
          )}
        </div>

        <h4>{getText('paymentStatus')}</h4>
        <div className="order-details-payment-method-card">
          {isNil(payment) ? (
            <Skeleton circle width="3rem" height="3rem" marginRight="2em" />
          ) : (
            <Icon iconName={cryptoConfig?.icon} className="order-details-payment-method-avatar" />
          )}
          <div className="order-details-payment-method">
            <h5>{getText(cryptoConfig?.title)}</h5>
            {isNil(payment) ? (
              <Skeleton width="9rem" height="1.5rem" />
            ) : (
              <p className="gray order-details-payment-sub-label">{getText(cryptoConfig?.label)}</p>
            )}
          </div>
          <div className="order-details-payment-price-container">
            {isNil(payment) ? (
              <>
                <Skeleton width="5rem" marginBottom="2em" />
                <Skeleton width="7rem" height="1.5rem" />
              </>
            ) : (
              <>
                <h5 className="order-details-payment-price">
                  {printPrice({ ...printPriceProps, crypto: totalPriceCrypto })}
                </h5>
                <Tag type={paymentStatusColor[payment?.cryptoPaymentStatus]} fontSize="0.8rem">
                  {paymentStatusInverse[payment?.cryptoPaymentStatus]}
                </Tag>
              </>
            )}
          </div>
        </div>

        {!!totalPriceFiat && (
          <div className="order-details-payment-method-card">
            <Icon iconName={cashConfig?.icon} className="order-details-payment-method-avatar" />
            <div className="order-details-payment-method">
              <h5>{getText(cashConfig?.title)}</h5>
              <p className="gray order-details-payment-sub-label">{getText(cashConfig?.label)}</p>
            </div>
            <div className="order-details-payment-price-container">
              <h5 className="order-details-payment-price">
                {printPrice({
                  fiat: totalPriceFiat,
                  ...printPriceProps,
                  fiatDesiredCurrency: isFiatManualPayment ? currencyCode : DEFAULT_FIAT_CURRENCY.code,
                })}
              </h5>
              <Tag type={paymentStatusColor[payment?.cashPaymentStatus]} fontSize="0.8rem">
                {paymentStatusInverse[payment?.cashPaymentStatus]}
              </Tag>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
