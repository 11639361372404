import { useState } from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'components/UIExternal';
import AddressCardEdit from './Edit';
import AddressCardPreview from './Preview';
import { addressBox, editFormSectionBtn } from './styles';

export const AddressCard = ({ address, editable, onEdit, addressId }) => {
  const { getDynamicTranslation } = useTranslations();
  const [isEditMode, setIsEditMode] = useState(false);

  const handleEdit = async (data) => {
    isFunction(onEdit) && (await onEdit(data));
    setIsEditMode(false);
  };

  const handleCancel = () => setIsEditMode(false);

  return (
    <div css={addressBox}>
      <div className="address-card-header">
        <h2>{getDynamicTranslation(address.name)?.name}</h2>
        {editable && (
          <Button
            small
            type="default"
            className="address-card-edit-button"
            onClick={() => setIsEditMode((prev) => !prev)}
            css={editFormSectionBtn}>
            {isEditMode ? <CloseOutlined /> : <EditOutlined />}
          </Button>
        )}
      </div>
      <hr className="address-card-separator" />
      {isEditMode ? (
        <AddressCardEdit addressId={addressId} address={address} onEdit={handleEdit} onCancel={handleCancel} />
      ) : (
        <AddressCardPreview address={address} />
      )}
    </div>
  );
};

AddressCard.propTypes = {
  address: PropTypes.object,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  addressId: PropTypes.number,
};
