import { theme } from 'styles';

export const navigationContainer = {
  backgroundColor: theme.white,
  boxShadow: `0px 3px 3px ${theme.gray_rgba}`,
  position: 'sticky',
  top: 0,
  zIndex: 1000,

  '.search-input': {
    width: '18.75rem',
  },
};

export const navigationContent = {
  display: 'flex',
  justifyContent: 'space-between',
  color: 'theme.white',
  paddingTop: '0.8125em',
  paddingBottom: '0.8125em',
  '& .ant-select-auto-complete': {
    marginLeft: '1.75em',
  },
  [theme.Mobile]: {
    justifyContent: 'flex-start',
    position: 'relative',
    '& .megaMenuContainer': {
      display: 'flex',
    },
    '& .megaMenu': {
      top: '7.125rem',
    },
    '& .localization-panel': {
      padding: '0.375em 1em',
    },
  },
};

export const actionsPanel = {
  display: 'flex',
  alignItems: 'center',
  flex: '1',
  justifyContent: 'flex-end',
};
