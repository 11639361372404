import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Avatar, Badge, Popover } from 'antd';
import { Button } from 'components/UIExternal';
import { notificationTime, notificationType } from 'components/ui/Notification/style';
import { Icon } from 'components/ui';
import { useUser } from 'stores/contexts/User';
import { getTimeFromNow } from 'utils';
import { getCacheVal, removeCacheVal, setCacheVal } from 'utils/cacheUtils';
import { getUnreadChat } from 'services/api/chatService';
import noChatsImg from 'assets/images/chat/start-conversation.png';
import noChatServiceImg from 'assets/images/chat/chat-out-of-service.png';
import {
  popoverWrapper,
  badgeContainer,
  noNotificationPanelContainer,
  noNotificationsInnerContainer,
  noNotificationImage,
  badgeNumber,
  linkMessageButton,
  serviceMessageButton,
  popoverButtonContainer,
} from './styles';

const ChatMiniPanel = () => {
  const { isAuthenticated } = useUser();
  const { getText, getDynamicTranslation } = useTranslations();
  const [visible, setVisible] = useState(false);
  const [messages, setMessages] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  const isChatOutOfService = () => getCacheVal('chat-out-of-service');

  useEffect(() => {
    errorCount >= 3 && setCacheVal('chat-out-of-service', true);
  }, [errorCount]);

  useEffect(() => {
    callChatMessages();
    const chatMessageInterval = setInterval(() => callChatMessages, 3000);
    return () => clearInterval(chatMessageInterval);
  }, []);

  useEffect(() => {
    isAuthenticated &&
      !isChatOutOfService() &&
      getUnreadChat(1, 6).then((res) => {
        if (res[0]) {
          setMessages(res[0].items);
          setTotalCount(res[0].totalCount > 5 ? '5+' : res[0].totalCount);
        }
        if (res[1]?.status === 500) setErrorCount((prev) => prev + 1);
      });
  }, [isAuthenticated]);

  const callChatMessages = async () => {
    if (isAuthenticated && !isChatOutOfService()) {
      await getUnreadChat(1, 6).then((res) => {
        if (res[0]) {
          setMessages(res[0].items);
          setTotalCount(res[0].totalCount > 5 ? '5+' : res[0].totalCount);
        }
        if (res[1]?.status === 500) setErrorCount((prev) => prev + 1);
      });
    }
  };

  const contentNoMessages = (
    <div css={noNotificationPanelContainer}>
      <div css={noNotificationsInnerContainer}>
        <img css={noNotificationImage} src={noChatsImg} alt="Logo" />
        <h4>{getText('youDontHaveAnyMessagesYet')}</h4>
        <Button type="link" linkTo="/messages" css={linkMessageButton} onClick={() => setVisible(!visible)}>
          {getText('goToAllMessages')}
        </Button>
      </div>
    </div>
  );

  const contentChatOutOfService = (
    <div css={noNotificationPanelContainer}>
      <div css={noNotificationsInnerContainer}>
        <img css={noNotificationImage} src={noChatServiceImg} alt="Logo" />
        <h4>{getText('messagingServiceCurrentlyUnavailable')}</h4>
        <Button
          type="default"
          small
          css={serviceMessageButton}
          onClick={() => {
            setErrorCount(0);
            removeCacheVal('chat-out-of-service');
          }}>
          {getText('checkNewMessages')}
        </Button>
      </div>
    </div>
  );

  const messageContent = (message, rowIdx) => {
    const dealTitle = getDynamicTranslation(message.dealTitle)?.title;

    return (
      <Link key={rowIdx} to={`/messages/chat?chatId=${message.chatId}`} style={{ marginBottom: 8 }} className="flex">
        <Avatar.Group style={{ marginRight: 5 }} maxCount={2} className="flex flex-center">
          {message?.participants?.map((participant, i) => (
            <Avatar key={i} src={participant.avatarThumbnailUrl}>
              {participant.firstName?.charAt(0)}
            </Avatar>
          ))}
        </Avatar.Group>
        <div style={{ justifyContent: 'center' }} className="flex flex-column ">
          <div className="flex">
            <label css={notificationType}>{message.name}</label>
            <label css={notificationTime}>{getTimeFromNow(message.lastMessageDate)}</label>
          </div>
          {message.dealTitle?.length && (
            <p>{message.dealTitle.length > 70 ? dealTitle.substring(0, 70).concat('...') : dealTitle}</p>
          )}
        </div>
      </Link>
    );
  };

  const content = (
    <div className="flex flex-column">
      <h3> {getText('yourMessages')}</h3>
      {totalCount && messages.map((msg, i) => messageContent(msg, i))}
      <Button type="link" linkTo="/messages" css={linkMessageButton} onClick={() => setVisible(false)}>
        {getText('goToMessages')}
      </Button>
    </div>
  );

  const popoverContent = () => {
    if (isChatOutOfService()) return contentChatOutOfService;
    if (totalCount) return content;
    return contentNoMessages;
  };

  return (
    <Popover
      visible={visible}
      onVisibleChange={() => setVisible(!visible)}
      content={popoverContent()}
      overlayClassName={popoverWrapper}
      placement="bottomRight"
      trigger="click">
      <button onClick={() => setVisible(!visible)} css={popoverButtonContainer}>
        <Badge css={badgeContainer} count={<label css={badgeNumber(true, totalCount)}>{totalCount}</label>}>
          <Icon iconName="lar la-comment-alt" />
        </Badge>
      </button>
    </Popover>
  );
};

export default ChatMiniPanel;
