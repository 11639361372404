import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';

const AddressCardPreview = ({ address }) => {
  const { getText } = useTranslations();

  return (
    <>
      {/* <label className="address-card-label">{getText('assignToMerchantProfiles')}:</label>
      <p className="address-card-text">{getDynamicTranslation(address.businessNames)?.name}</p> */}

      <label className="address-card-label">{getText('country')}:</label>
      <p className="address-card-text">{address.country}</p>

      <label className="address-card-label">{getText('city')}:</label>
      <p className="address-card-text">{address.city}</p>

      <label className="address-card-label">{getText('postcode')}:</label>
      <p className="address-card-text">{address.postcode}</p>

      <label className="address-card-label">{getText('streetAddress')}:</label>
      <p className="address-card-text">{address.street}</p>

      <label className="address-card-label">{getText('contactPerson')}:</label>
      <p className="address-card-text">{address.contactPerson}</p>

      <label className="address-card-label">{getText('phoneNumber')}:</label>
      <p className="address-card-text">+{address.phone}</p>

      <label className="address-card-label">{getText('workDays')}:</label>
      <p className="address-card-text">{address.workingDays}</p>

      <label className="address-card-label">{getText('workingHours')}:</label>
      <p className="address-card-text">{address.workingHours}</p>
    </>
  );
};

AddressCardPreview.propTypes = {
  address: PropTypes.object,
};

export default AddressCardPreview;
