import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce, isFunction } from 'lodash-es';
import { Input } from 'antd';
import { Icon } from 'components/ui';
import { inputContainer } from './styles';

export const TextInput = (props) => {
  const {
    placeholder,
    onSearch,
    onPressEnter,
    value,
    defaultValue,
    noValue,
    showDelete = false,
    showSearch = false,
    className,
    disabled,
  } = props;
  const inputRef = useRef();

  useEffect(() => {
    const closeIcon = inputRef.current?.input?.parentNode?.querySelector('.ant-input-clear-icon');
    if (closeIcon) closeIcon.onclick = (e) => isFunction(onPressEnter) && onPressEnter('', e);
  }, [onPressEnter]);

  const onChange = debounce((data) => {
    isFunction(onSearch) && onSearch(data.target.value);
  }, 500);

  return (
    <Input
      disabled={disabled}
      ref={inputRef}
      css={inputContainer}
      className={`search-input ${className}`}
      placeholder={placeholder}
      onChange={onChange}
      onPressEnter={onPressEnter}
      value={noValue ? undefined : value || undefined}
      defaultValue={defaultValue}
      allowClear={showDelete}
      {...(showSearch && { prefix: <Icon iconName="las la-search" size={20} /> })}
    />
  );
};

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  noValue: PropTypes.bool,
  onSearch: PropTypes.func,
  onPressEnter: PropTypes.func,
  showDelete: PropTypes.bool,
  showSearch: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
