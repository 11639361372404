import PropTypes from 'prop-types';
import { pick, isNumber } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Form, Input, Switch } from 'antd';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useCurrencies } from '@oneecosystem/dealshaker-core';
import { FormEditButtons } from '../../../shared';
import { Dropdown } from 'components/UIExternal';
import { businessTypeSelect, businessType } from 'enums';
import { formStyles } from './styles';
import { useState } from 'react';

const formFields = ['intendedDeals', 'registrationNumber', 'vatNumber', 'allowsBVPoints', 'typeId', 'currencyCode'];

const BusinessAdditionalInformationEdit = ({ business, onEdit, onCancel }) => {
  const { getText } = useTranslations();
  const fiatCurrencies = useCurrencies((currenciesState) => currenciesState?.fiatCurrencies);
  const [form] = Form.useForm();
  const mandatoryRule = {
    required: true,
    message: getText('fieldIsRequired'),
  };
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  const [showBusinessFields, setShowBusinessFields] = useState(business?.typeId === businessType.business);

  const handleValueChange = (changedValues) => {
    if (isNumber(changedValues?.typeId)) setShowBusinessFields(changedValues?.typeId === businessType.business);
  };

  const handleSaveForm = async (values) => {
    await onEdit(values, formFields);
    onCancel();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={pick(business, formFields)}
      onValuesChange={handleValueChange}
      onFinish={handleSaveForm}
      css={formStyles}>
      <Form.Item label={getText('dealsRunOnDialShaker')} name="intendedDeals" style={{ width: '40%' }}>
        <Input.TextArea placeholder={getText('intendedDealsEdit')} />
      </Form.Item>
      <Form.Item label={getText('merchantType')} name="typeId" rules={[mandatoryRule]} style={{ width: '40%' }}>
        <Dropdown
          small
          noClear
          placeholder={getText('merchantType')}
          options={businessTypeSelect(getText)}
          mapValue={(el) => el?.value}
        />
      </Form.Item>
      {showBusinessFields && (
        <>
          <Form.Item
            label={getText('companyRegistrationNumber')}
            name="registrationNumber"
            style={{ width: '40%' }}
            rules={[
              showBusinessFields && mandatoryRule,
              {
                max: 22,
                message: getText('fieldShouldNotExceedCountSymbols', {
                  field: getText('registrationNumber'),
                  count: 22,
                }),
              },
            ]}>
            <Input type="text" />
          </Form.Item>
          <Form.Item label={getText('vatNumber')} name="vatNumber" style={{ width: '40%' }}>
            <Input />
          </Form.Item>
        </>
      )}
      {isAdmin && (
        <Form.Item label={getText('bvPointsAllowed')} name="allowsBVPoints" valuePropName="checked">
          <Switch />
        </Form.Item>
      )}
      <Form.Item label={getText('currency')} name="currencyCode" rules={[mandatoryRule]} style={{ width: '40%' }}>
        <Dropdown
          small
          noClear
          placeholder={getText('currency')}
          options={fiatCurrencies?.map((el) => ({ label: `${el?.code} ${el?.symbol}`, value: el.code }))}
          mapValue={(el) => el?.value}
        />
      </Form.Item>
      <FormEditButtons onCancel={onCancel} />
    </Form>
  );
};

BusinessAdditionalInformationEdit.propTypes = {
  business: PropTypes.object,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default BusinessAdditionalInformationEdit;
