import { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import { isFunction } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import modalIcon from 'assets/images/DeclineModal.svg';
import { commentArea, modelContentWrapper, cancelButtonStyle, commentModalActions, addButtonStyle } from './styles';

const CommentModal = ({ isVisible, onAccept, onCancel }) => {
  const { getText } = useTranslations();
  const [comment, setComment] = useState('');

  return (
    <Modal visible={isVisible} width={580} zIndex={1100} onCancel={onCancel} css={modelContentWrapper} footer={null}>
      <img src={modalIcon} className="iconModal" alt="prop" />
      <p className="section-subtitle">{getText('addComment')}</p>
      <Input.TextArea
        placeholder={getText('yourComment')}
        value={comment}
        css={commentArea}
        autoSize
        onChange={(e) => setComment(e.target.value)}
      />
      <div css={commentModalActions}>
        <Button
          type="secondary"
          small
          css={cancelButtonStyle}
          onClick={() => {
            onCancel();
            setComment('');
          }}>
          {getText('cancel')}
        </Button>
        <Button
          type="link"
          small
          disabled={!comment}
          onClick={() => {
            isFunction(onAccept) && onAccept(comment);
            setComment('');
          }}
          css={addButtonStyle}>
          {getText('add')}
        </Button>
      </div>
    </Modal>
  );
};

CommentModal.propTypes = {
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default CommentModal;
