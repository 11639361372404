import { theme } from 'styles';

export const configurationSettingsInput = {
  margin: '.5em 0 1.5em',
  maxWidth: '18.75rem',
};

export const submitActions = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1.5em 0 0.5em',
};

export const formErrorMessage = {
  marginRight: '0.5em',
  color: theme.red,
};
