import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { isMobile } from 'react-device-detect';
import { Button } from 'components/UIExternal';
import { TextInput, Icon } from 'components/ui';
import UserHeaderActions from './UserHeaderActions';
import { navigationContainer, navigationContent } from './styles';

const Header = ({ toggleNavigationDrawer, isVisible }) => {
  const { getText } = useTranslations();
  const history = useHistory();
  const [queryString, setQueryString] = useState('');

  useEffect(() => {
    queryString?.length > 2 && history.push(`/deals?searchText=${queryString}`);
  }, [queryString]);

  const onSearch = (e) => setQueryString(e);
  const onSelect = (e) => setQueryString(e);

  return (
    <nav css={navigationContainer}>
      <div className="content-container" css={navigationContent}>
        {isMobile && !isVisible && (
          <Button type="info" onClick={toggleNavigationDrawer} small>
            <Icon iconName="las la-bars" color="black" />
          </Button>
        )}
        <TextInput
          showSearch
          onSearch={onSearch}
          onPressEnter={(e) => onSelect(e.target.value)}
          placeholder={getText('searchForDeals')}
        />
        <UserHeaderActions />
      </div>
    </nav>
  );
};

export default Header;
