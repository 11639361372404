import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Col } from 'antd';
import { hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { businessTypeInverse, businessType } from 'enums';
import { labelTitle } from './styles';

const BusinessAdditionalInformationPreview = ({ business }) => {
  const { getText } = useTranslations();
  const isAdmin = hasRole(USER_ROLES.DS_ADMIN);

  return (
    <>
      <Col lg={4} span={24}>
        <label>{getText('dealsRunOnDialShaker')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.intendedDeals}</p>
      </Col>
      <Col lg={4} span={24}>
        <label>{getText('merchantType')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{getText(businessTypeInverse[business?.typeId])}</p>
      </Col>
      {business?.typeId === businessType?.business && (
        <>
          <Col lg={4} span={24}>
            <label>{getText('companyRegistrationNumber')}</label>
          </Col>
          <Col lg={20} span={24}>
            <p>{business?.registrationNumber ?? '-'}</p>
          </Col>
        </>
      )}
      {business?.typeId === businessType?.business && (
        <>
          <Col lg={4} span={24}>
            <label>{getText('vatNumber')}</label>
          </Col>
          <Col lg={20} span={24}>
            <p>{business?.vatNumber ?? '-'}</p>
          </Col>
        </>
      )}
      {isAdmin && (
        <>
          <Col lg={4} span={24}>
            <span css={labelTitle}>{getText('bvPointsAllowed')}</span>
          </Col>
          <Col lg={20} span={24}>
            <p>{business?.allowsBVPoints ? getText('yes') : getText('no')}</p>
          </Col>
        </>
      )}
      <Col lg={4} span={24}>
        <label>{getText('currency')}</label>
      </Col>
      <Col lg={20} span={24}>
        <p>{business?.currencyCode}</p>
      </Col>
    </>
  );
};

BusinessAdditionalInformationPreview.propTypes = {
  deal: PropTypes.object,
  business: PropTypes.object,
};

export default BusinessAdditionalInformationPreview;
